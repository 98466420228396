import { sendGetRequest, sendPostRequest, sendDeleteRequest, sendPatchRequest } from '@/services/api.service'
import { API_LPE, GTR_CORE } from '@/config/api.config'

export const getClientsCoreApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.CLIENTS}?${urlParams}`)
}

export const getClientApi = clientId => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_CLIENT}/${clientId}`)
}

export const getClientCoreApi = uuid => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.CLIENTS}/${uuid}`)
}

export const getFeaturesListApi = clientId => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES_LIST}?client.id=${clientId}`)
}

export const postClientApi = client => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.CLIENTS}`, client)
}

export const patchClientApi = (uuid, client) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.CLIENTS}/${uuid}`, client)
}

export const updateClientApi = (clientId, data) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_CLIENT}/${clientId}`, data)
}

export const getLocalesApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_LOCALE}?size=200&search=status:1&sort=name`)
}

export const getActiveContractsApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.CONTRACTS}?${urlParams}`)
}

export const postActiveContractsApi = contract => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.CONTRACTS}`, contract)
}

export const patchActiveContractsApi = (urlParams, body) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.CONTRACTS}${urlParams}`, body)
}

export const createFeatureApi = (clientId, feature) => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES_LIST}?clientId=${clientId}`, feature)
}

export const updateFeatureApi = (clientId, featureId, feature) => {
  return sendPatchRequest(
    `${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES_LIST}/${featureId}?clientId=${clientId}`,
    feature
  )
}

export const deleteFeatureApi = (clientId, featureId) => {
  return sendDeleteRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURES_LIST}/${featureId}?clientId=${clientId}`)
}

export const getfrontOffices = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICES}?clientId=${clientId}`)
}
