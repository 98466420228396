const DEFAULT_API_URL = '/api'

export const API_USER = Object.freeze({
  BASE_API_URL: `${DEFAULT_API_URL}/user/`,
  LOGIN: 'login.php',
  LOGOUT: 'logout.php',
  FORGET_PASSWORD: 'forget_password.php',
  AUTH: 'auth.php',
  UPDATE_SETTINGS: 'update_settings.php',
  UPDATE_PASSWORD: 'update_password.php',
  RESET_PASSWORD: 'password.php',
})

export const API_AUTH = Object.freeze({
  INVITE_USER: '/auth/user/password/reset/token',
  SESSION_REFRESH: `/auth/session/refresh`,
})

export const API_TOOLS = {
  BASE_API_URL: `${DEFAULT_API_URL}/tools/`,
  UPLOAD_MEDIA: 'upload_media.php',
  REMOVE_MEDIA: 'remove_media.php',
}

export const API_LPE = {
  BASE_API_URL: `${DEFAULT_API_URL}/lpe/`,
  CLIENT: 'client_id_localized.php',
  FRONT_OFFICES: 'front-office.php',
  FEATURE: 'feature-list_id.php',
  FRONT_OFFICE_FEATURES_LIST: 'front-office_id_factory.php',
  LOCALES: 'locale.php',
}

export const API_GMB = {
  BASE_API_URL: `${DEFAULT_API_URL}/gmb/`,
  GMB: 'gmb/',
  CATEGORIES: 'categories.php',
  CHAINS: 'search_chains.php',
  INVITATIONS: 'invitations.php',
  ACCOUNTS: 'accounts_full.php',
  ACCOUNT: 'account_id.php',
  ACCOUNT_ASSOCIATION: 'account_id_association.php',
  ACCOUNT_NOTIFICATION: 'account_id_notification.php',
  ATTRIBUTES: 'attributes.php',
}

export const API_SA = {
  BASE_API_URL: `${DEFAULT_API_URL}/sa/`,
  BOT: 'bot/',
  PC: 'pc/',
  AR: 'ar/',
  QR: 'qr/',
  ACTIVITY: 'activity.php',
  BRANDS: 'brands.php',
  BRAND: 'brand_id.php',
  CAMPAIGN: 'campaign.php',
  ENDPOINTS: 'endpoint.php',
  SETTING: 'settings.php',
  GOOGLE_CATEGORIES: 'google_categories.php',
  BRAND_SETTINGS: 'brand_get_settings.php',
  SUPER_RGDPR: 'super_gdpr_user.php',
  CHECK_REVIEW_PAGES: 'check_review_pages.php',
  USER_ASSOCIATION: 'users-association.php',
}

export const API_PM = {
  BASE_API_URL: `${DEFAULT_API_URL}/pm/`,
  UPLOAD_FILE: `process-evm-file.php`,
  DOWNLOAD_FILE: `locations-export.php`,
}

export const GTR_CORE = {
  BASE_API_URL: `/gtr-core/`,
  AUTH: 'auth',
  BULK: 'bulk',
  CONTRACTS: 'contracts',
  CLIENTS: 'clients',
  VERTICALS: 'verticals',
  FEATURES: 'features',
  TYPES: 'types',
  MARKETS: 'markets',
  MEDIA: 'media',
  MEDIA_TYPES: 'media_types',
  ROLES: 'roles',
  USERS: 'users',
  MENUS: 'menus',
  LPE_CLIENT: 'lpe/client',
  LPE_LOCALE: 'lpe/locale',
  FEATURES_LIST: 'lpe/feature-list',
}
