import Vue from 'vue'
import Vuex from 'vuex'
import backoffice from './modules/backoffice.store'
import client from './modules/client.store'
import brand from './modules/brand.store'
import tool from './modules/tool.store'
import gmb from './modules/gmb.store'
import superRgpd from './modules/superRgpd.store'
import presencemanagement from './modules/presencemanagement.store'
import setting from './modules/setting.store'
import media from './modules/media.store'
import users from './modules/users.store'
import endpoints from './modules/endpoints.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    backoffice,
    client,
    brand,
    tool,
    gmb,
    superRgpd,
    presencemanagement,
    setting,
    media,
    users,
    endpoints,
  },
  strict: process.env.NODE_ENV !== 'production',
})
