import {
  getActiveContractsApi,
  patchActiveContractsApi,
  postActiveContractsApi,
  getClientsCoreApi,
  getClientCoreApi,
  getClientApi,
  patchClientApi,
  getFeaturesListApi,
  updateClientApi,
  postClientApi,
  createFeatureApi,
  updateFeatureApi,
  deleteFeatureApi,
  getfrontOffices,
} from '@/services/client.service'
import { buildCoreApiParams } from '@/utils/table.util'

const SET_CLIENTS = 'SET_CLIENTS'
const SET_AVAILABLE_FRONT_OFFICES = 'SET_AVAILABLE_FRONT_OFFICES'
const SET_CURRENT_FRONT_OFFICE = 'SET_CURRENT_FRONT_OFFICE'
const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'
const SET_FEATURES_LIST = 'SET_FEATURES_LIST'
const SET_ACTIVE_CONTRATS = 'SET_ACTIVE_CONTRATS'
const UPDATE_ACTIVE_CONTRATS = 'UPDATE_ACTIVE_CONTRATS'

const getDefaultState = () => {
  return {
    clients: [],
    currentClient: null,
    currentFrontOffice: null,
    availablesFrontOffices: [],
    featuresList: [],
    activeContracts: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_CLIENTS]: (state, clients) => {
    state.clients = clients
  },
  [SET_CURRENT_CLIENT]: (state, client) => {
    state.currentClient = client
  },
  [SET_AVAILABLE_FRONT_OFFICES]: (state, frontOffices) => {
    state.availablesFrontOffices = frontOffices
  },
  [SET_CURRENT_FRONT_OFFICE]: (state, frontOffice) => {
    state.currentFrontOffice = frontOffice
  },
  [SET_FEATURES_LIST]: (state, featuresList) => {
    state.featuresList = featuresList
  },
  [SET_ACTIVE_CONTRATS]: (state, activeContracts) => {
    state.activeContracts = activeContracts
  },
  [UPDATE_ACTIVE_CONTRATS]: (state, updatedContrat) => {
    const index = state.activeContracts.findIndex(modelContract => modelContract.id === updatedContrat.id)
    if (updatedContrat.status === false) {
      state.activeContracts.splice(index, 1)
    } else {
      state.activeContracts.splice(index, 1, updatedContrat)
    }
  },
}

const actions = {
  async getClientsCore({ commit }, { query, options }) {
    const urlParams = buildCoreApiParams({ query, options })
    const results = await getClientsCoreApi(urlParams)
    commit(SET_CLIENTS, results)
    return Promise.resolve()
  },
  async getClientCore({ commit }, { uuid }) {
    const client = await getClientCoreApi(uuid)
    commit(SET_CURRENT_CLIENT, client)
  },
  async patchClient({ commit }, { uuid, client }) {
    const updateClient = await patchClientApi(uuid, client)
    commit(SET_CURRENT_CLIENT, updateClient)
  },
  async getClient({ commit }, { clientId }) {
    const client = await getClientApi(clientId)
    commit(SET_CURRENT_CLIENT, client)
  },
  async getClientFrontOffices({ commit }, { clientId }) {
    const { data } = await getfrontOffices(clientId)
    commit(SET_AVAILABLE_FRONT_OFFICES, data.results)
    commit(SET_CURRENT_FRONT_OFFICE, data.results[0])
  },
  async setCurrentFrontOffice({ commit }, { frontOffice }) {
    commit(SET_CURRENT_FRONT_OFFICE, frontOffice)
  },
  async getFeaturesList({ commit }, { clientId }) {
    const { results } = await getFeaturesListApi(clientId)
    commit(SET_FEATURES_LIST, results)
  },
  async getActiveContracts({ commit }, { query }) {
    const urlParams = buildCoreApiParams({ query })
    const results = await getActiveContractsApi(urlParams)
    commit(SET_ACTIVE_CONTRATS, results)
  },
  async postActiveContracts({ commit, state }, { contract }) {
    const results = await postActiveContractsApi(contract)
    commit(SET_ACTIVE_CONTRATS, [...state.activeContracts, results])
  },
  async patchActiveContracts({ commit }, { params, body }) {
    const urlParams = buildCoreApiParams({ params })
    const results = await patchActiveContractsApi(urlParams, body)
    commit(UPDATE_ACTIVE_CONTRATS, results)
  },
  async createClient({ commit }, client) {
    const newClient = await postClientApi(client)
    commit(SET_CURRENT_CLIENT, newClient)
  },
  async updateClient({ commit }, { client }) {
    const clientUpdate = await updateClientApi(client.id, client)
    commit(SET_CURRENT_CLIENT, clientUpdate)
  },
  async manageFeature(_, { feature, clientId }) {
    if (feature.tmpStatus === 'create') {
      await createFeatureApi(clientId, feature)
    } else if (feature.tmpStatus === 'update') {
      await updateFeatureApi(clientId, feature.id, feature)
    } else if (feature.tmpStatus === 'delete') {
      await deleteFeatureApi(clientId, feature.id)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
